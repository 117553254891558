import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {SysLoginControllerService} from "../../generated";
import ACCESS_ENUM from "@/access/ACCESS_ENUM";
import {removeToken, setToken} from "@/plugins/myToken";


export const useLoginUserStore = defineStore('loginUser', () => {
    const loginUser: any = ref({
        'userName': '',
        'avatar': ''
    })

    const getLoginUser = async () => {
        const res: any = await SysLoginControllerService.getInfoUsingGet6();
        if (res.code === 200) {
            updateUser(res.user);
            return res.user;
        } else {
            updateUser({...loginUser.value, userRole: ACCESS_ENUM.NOT_LOGIN})
        }
    }
    const removeLoginUser = () => {
        updateUser({
            'userName': ''
        })
        removeToken();
        localStorage.removeItem('userInfo');
    }
    const updateUser = (payload: any) => {
        loginUser.value = payload;
    }
    //用户登录
    const login = async (formData: any) => {
        const res: any = await SysLoginControllerService.loginByFrontedUsingPost(formData);
        if (res.code === 200) {
            setToken(res.token);
            const user = await getLoginUser();
            localStorage.setItem('userInfo', JSON.stringify(loginUser.value));
            return user;
        } else {
            return false;
        }
    }
    const isLogin = computed(() => {
        if (loginUser.value.userName === '') {
            return false;
        }
        return true;
    })
    return {loginUser, getLoginUser, login, removeLoginUser, isLogin, updateUser}
})
