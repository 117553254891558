/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {TableDataInfo} from '../models/TableDataInfo';
import type {XiaoniuProject} from '../models/XiaoniuProject';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import {ProjectOrder} from "../models/ProjectOrder";
import {XiaoniuOrder} from "../models/XiaoniuOrder";
import myAxios from "@/plugins/myAxios";
import {XiaoniuMemberOrder} from "../models/XiaoniuMemberOrder";

export class XiaoniuFrontedControllerService {


    /**
     * 查询视频列表
     */
    public static getVideoInfo(query:any) {
        return myAxios({
            url: '/baseinfo/fronted/video/list',
            method: 'get',
            params: query
        });
    }





    /**
     * addMemberOrder
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addMemberOrderUsingPost(
        requestBody?: XiaoniuMemberOrder,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/member/addOrder',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    /**
     * payMember
     * @param orderNo orderNo
     * @returns any OK
     * @throws ApiError
     */
    public static payMemberUsingPost(
        orderNo: string,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/member/prePay/{orderNo}',
            path: {
                'orderNo': orderNo,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * list
     * @param createBy
     * @param createTime
     * @param id
     * @param name
     * @param params
     * @param period
     * @param price
     * @param remark
     * @param searchValue
     * @param shoppingName
     * @param updateBy
     * @param updateTime
     * @returns any OK
     * @throws ApiError
     */
    public static listUsingGet14(
        createBy?: string,
        createTime?: string,
        id?: number,
        name?: string,
        params?: Record<string, any>,
        period?: number,
        price?: number,
        remark?: string,
        searchValue?: string,
        shoppingName?: string,
        updateBy?: string,
        updateTime?: string,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/memberinfo/list',
            query: {
                'createBy': createBy,
                'createTime': createTime,
                'id': id,
                'name': name,
                'params': params,
                'period': period,
                'price': price,
                'remark': remark,
                'searchValue': searchValue,
                'shoppingName': shoppingName,
                'updateBy': updateBy,
                'updateTime': updateTime,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    // 用户密码重置
    public static updateUserPwd(oldPassword:string, newPassword:string) {
        const data = {
            oldPassword,
            newPassword
        }
        return myAxios({
            url: '/system/user/profile/updatePwd',
            method: 'put',
            params: data
        })
    }

    /**
     * 修改个人信息
     */
    public static updateUserProfile(data: any) {
        return myAxios({
            url: '/system/user/profile',
            method: 'put',
            data: data
        });
    }

    /**
     * 查询个人信息
     */
    public static getPersonInfo() {
        return myAxios({
            url: '/baseinfo/fronted/personInfo',
            method: 'get',
        });
    }


    /**
     * avatar
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static avatarUsingPost1(data: any) {
        return myAxios({
            url: '/baseinfo/fronted/avatar',
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    /**
     * topFiveBlog
     * @returns any OK
     * @throws ApiError
     */
    public static topFiveBlogUsingPost(): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/topFiveBlog',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * addBlogView
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static addBlogViewUsingPost(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/addBlogView/add/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getArticleDetail
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getArticleDetailUsingGet(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/getArticleDetail/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * topEightArticles
     * @returns any OK
     * @throws ApiError
     */
    public static topEightArticlesUsingGet(): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/topEightArticles',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    /**
     * topFiveHomework
     * @returns any OK
     * @throws ApiError
     */
    public static topFiveHomeworkUsingPost(): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/topFiveHomework',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getHomeworkBuyCount
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getHomeworkBuyCountUsingPost(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/homework/countBuy/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getCourseInfo
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getCourseInfoUsingGet(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/homework/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * addHomeworkView
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static addHomeworkViewUsingPost(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/homeworkView/add/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getHomeworkView
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getHomeworkViewUsingPost(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/homeworkView/countView/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    /**
     * topFiveProject
     * @returns any OK
     * @throws ApiError
     */
    public static topFiveProjectUsingPost(): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/topFiveProject',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    /**
     * getProjectView
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getCountProjectBuy(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/project/countBuy/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    /**
     * addProjectView
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static addProjectViewUsingPost(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/projectView/add/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getProjectView
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getProjectViewUsingPost(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/projectView/countView/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    /**
     * 获取课设列表
     * @param id
     */
    public static getCourseDetail(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/homework/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 删除订单
     */
    public static removeUsingDelete15(
        ids: string[] | number[], // Adjust the type based on your use case
    ): CancelablePromise<Record<string, Record<string, any>>> {
        // Convert the array of IDs into a comma-separated string for URL
        const idsString = ids.join(',');

        return __request(OpenAPI, {
            method: 'DELETE',
            url: `/baseinfo/fronted/order/${idsString}`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }


    /**
     * getInfo
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getInfoUsingGet18(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/project/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    public static listUsingGet15(): CancelablePromise<TableDataInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/info/list',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 项目下单 --------会员
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addMemberOrder(
        requestBody?: ProjectOrder,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/addOrderMember',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 项目下单
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addUsingPost13(
        requestBody?: ProjectOrder,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/addOrder',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 课设下单
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addOrderCourse(
        requestBody?: ProjectOrder,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/course/addOrder',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * pay
     * @param 支付接口
     * @returns any OK
     * @throws ApiError
     */
    public static payUsingPost(
        orderNo: string,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/fronted/prePay/{orderNo}',
            path: {
                'orderNo': orderNo,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * 订单列表
     * @param requestBody
     */
    public static listUsingGet16(
        requestBody?: any,
    ): CancelablePromise<TableDataInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/fronted/order/list',
            query: requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
