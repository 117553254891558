import {createApp, nextTick} from 'vue'
import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/reset.css';
import Antd from 'ant-design-vue';
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {createPinia} from 'pinia'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./styles/font/font.css"
const pinia = createPinia()
import '@/access'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import MyPagination from "@/components/tool/MyPagination.vue";
import DictTag from "@/components/tool/DictTag.vue";

import store from './store'

import "./styles/base.css"

//全局指令
import {highLightPlugin} from '@/directivies/directivePlugin'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
//全局组件
app.component('MyPagination', MyPagination);
app.component('DictTag', DictTag);


//全局指令
app.use(highLightPlugin)
app.use(store)
app.use(Toast);
// app.use(ElementPlus);
app.use(router).use(Antd).use(pinia).mount('#app')
