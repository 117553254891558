export default {
  name: "ColorPicker",
  props: {
    color: {
      type: String,
      default() {
        return '#FFFFFF';
      }
    }
  },
  data() {
    return {
      hexColor: this.color
    };
  },
  methods: {
    validateColor() {
      // 使用正则表达式验证颜色格式
      const hexRegex = /^#[0-9A-Fa-f]{6}$/;
      if (hexRegex.test(this.hexColor)) {
        // 如果格式正确，更新color属性
        this.$emit("update:color", this.hexColor);
      }
    },
    selectColor(color) {
      this.$emit("update:color", color);
    }
  },
  watch: {
    "color"(curr) {
      this.hexColor = curr;
    }
  }
};