/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TableDataInfo } from '../models/TableDataInfo';
import type { XiaoniuHomework } from '../models/XiaoniuHomework';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import {XiaoniuProject} from "../models/XiaoniuProject";

export class XiaoniuHomeworkControllerService {

    /**
     * edit
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static editUsingPut10(
        requestBody?: XiaoniuHomework,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/baseinfo/homework',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * add
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addUsingPost10(
        requestBody?: XiaoniuHomework,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/homework',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * export
     * @param createBy
     * @param createTime
     * @param id
     * @param link
     * @param name
     * @param params
     * @param payLink
     * @param price
     * @param remark
     * @param searchValue
     * @param type
     * @param updateBy
     * @param updateTime
     * @returns any OK
     * @throws ApiError
     */
    public static exportUsingPost9(
        createBy?: string,
        createTime?: string,
        id?: number,
        link?: string,
        name?: string,
        params?: Record<string, any>,
        payLink?: string,
        price?: number,
        remark?: string,
        searchValue?: string,
        type?: number,
        updateBy?: string,
        updateTime?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/baseinfo/homework/export',
            query: {
                'createBy': createBy,
                'createTime': createTime,
                'id': id,
                'link': link,
                'name': name,
                'params': params,
                'payLink': payLink,
                'price': price,
                'remark': remark,
                'searchValue': searchValue,
                'type': type,
                'updateBy': updateBy,
                'updateTime': updateTime,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


    public static listUsingGet13(
        requestBody?:XiaoniuHomework,
    ): CancelablePromise<TableDataInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/homework/list',
            query:requestBody,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getInfoOnly
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getInfoOnlyUsingGet(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/homework/only/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * remove
     * @param ids ids
     * @returns any OK
     * @throws ApiError
     */
    public static removeUsingDelete12(
        ids: string,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/baseinfo/homework/{ids}',
            path: {
                'ids': ids,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * getInfo
     * @param id id
     * @returns any OK
     * @throws ApiError
     */
    public static getInfoUsingGet14(
        id: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/baseinfo/homework/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
