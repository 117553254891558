/**
 * 毫秒转换友好的显示格式
 * 输出格式：21小时前
 * @param  {number} timestamp - 时间戳（以秒为单位）
 * @return {string}           - 友好的时间格式
 */
export  function beautifyTime(timestamp: number): string {
    const now:any = new Date();
    const date:any = new Date(timestamp);
    const diff = (now - date) / 1000; // 毫秒转换为秒

    if (diff < 60) {
        return "刚刚";
    } else if (diff < 3600) {
        return Math.floor(diff / 60) + "分钟前";
    } else if (diff < 86400) {
        return Math.floor(diff / 3600) + "小时前";
    } else if (diff < 604800) {
        return Math.floor(diff / 86400) + "天前";
    } else {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    }
}
