import router from "@/router";
import ACCESS_ENUM from "@/access/ACCESS_ENUM";
import checkAccess from "@/access/checkAccess";
import {useLoginUserStore} from "@/store/loginUser";

/**
 * 全局权限管理
 */
router.beforeEach(async (to, from, next) => {
    const loginUserStore = useLoginUserStore();
    if (!loginUserStore.loginUser || !loginUserStore.loginUser.userRole) {
        //获取用户信息
        await loginUserStore.getLoginUser();
    }
    //校验权限
    const needAccess = (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;

    if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
        if (!loginUserStore.loginUser || loginUserStore.loginUser.userRole == ACCESS_ENUM.NOT_LOGIN) {
            //跳转到登录页
            next("/user/login?redirect=" + to.fullPath)
            return;
        }
        if (!checkAccess(loginUserStore.loginUser, needAccess)) {
            next("/noAuth")
            return;
        }
    }
    next();
})


// ====================================================vuex的写法===================================================
// import router from "@/router";
// import store from "@/store";
// import ACCESS_ENUM from "@/access/ACCESS_ENUM";
// import checkAccess from "@/access/checkAccess";
// import {useLoginUserStore} from "@/store/loginUser";
//
// /**
//  * 全局权限管理
//  */
// router.beforeEach(async (to, from, next) => {
//
//     const loginUser = store.state.user?.loginUser;
//     if (!loginUser || !loginUser.userRole) {
//         //获取用户信息
//         await store.dispatch("user/getLoginUser");
//     }
//     console.log(loginUser)
//     //校验权限
//     const needAccess = (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;
//
//     if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
//         if (!loginUser || loginUser.userRole == ACCESS_ENUM.NOT_LOGIN) {
//             //跳转到登录页
//             next("/user/login?redirect=" + to.fullPath)
//             return;
//         }
//         if (!checkAccess(loginUser, needAccess)) {
//             next("/noAuth")
//             return;
//         }
//     }
//     next();
// })
