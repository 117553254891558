import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-58cc01bd"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pagination-container"
};
import { ref, watch } from 'vue';
import { scrollTo } from '@/plugins/scroll-to';

// 定义接收父组件传递的 props

export default {
  __name: 'MyPagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    pageSizeOptions: {
      type: Array,
      default: () => ['8', '16', '24', '32', '40', '48']
    },
    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:currentPage', 'update:pageSize', 'change'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;

    // 定义 emit 函数，以通知父组件有关页面变化
    const emit = __emit;

    // 创建本地响应式变量
    const localCurrentPage = ref(props.currentPage);
    const localPageSize = ref(props.pageSize);

    // 监听 props 的变化，并更新本地变量
    watch(() => props.currentPage, newValue => {
      localCurrentPage.value = newValue;
    });
    watch(() => props.pageSize, newValue => {
      localPageSize.value = newValue;
    });

    // 处理页面变化的函数，并发射必要的事件
    function handlePageChange(page, pageSize) {
      localCurrentPage.value = page;
      localPageSize.value = pageSize;
      emit('update:currentPage', page); // 更新当前页码 emit代表传给父组件
      emit('update:pageSize', pageSize); // 更新每页条目数
      emit('change', page, pageSize); // 发射自定义的 `change` 事件
      if (props.autoScroll) {
        scrollTo(0, 800);
      }
    }
    return (_ctx, _cache) => {
      const _component_a_pagination = _resolveComponent("a-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_a_pagination, {
        class: "pagination-wrapper",
        current: localCurrentPage.value,
        "onUpdate:current": _cache[0] || (_cache[0] = $event => localCurrentPage.value = $event),
        pageSize: localPageSize.value,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => localPageSize.value = $event),
        total: __props.total,
        "page-size-options": __props.pageSizeOptions,
        onChange: handlePageChange
      }, {
        buildOptionText: _withCtx(props => [_createElementVNode("span", null, _toDisplayString(props.value) + "条/页", 1)]),
        _: 1
      }, 8, ["current", "pageSize", "total", "page-size-options"]), [[_vShow, __props.total > 0]])]);
    };
  }
};