import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
import { ElMessage } from "element-plus";
import { XiaoniuFrontedControllerService } from "../../../generated/services/XiaoniuFrontedControllerService";

// 表单数据

export default {
  __name: 'ResetPwd',
  setup(__props) {
    const user = ref({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });

    // 表单校验规则
    const rules = {
      oldPassword: [{
        required: true,
        message: '旧密码不能为空',
        trigger: 'blur'
      }],
      newPassword: [{
        required: true,
        message: '新密码不能为空',
        trigger: 'blur'
      }, {
        min: 6,
        max: 20,
        message: '长度在 6 到 20 个字符',
        trigger: 'blur'
      }],
      confirmPassword: [{
        required: true,
        message: '确认密码不能为空',
        trigger: 'blur'
      }, {
        validator: (rule, value, callback) => {
          if (user.value.newPassword !== value) {
            callback(new Error('两次输入的密码不一致'));
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }]
    };

    // 表单引用
    const formRef = ref(null);

    // 提交方法
    const submit = () => {
      formRef.value.validate(valid => {
        if (valid) {
          XiaoniuFrontedControllerService.updateUserPwd(user.value.oldPassword, user.value.newPassword).then(response => {
            ElMessage.success("修改成功");
          });
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: user.value,
        rules: rules,
        "label-width": "80px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "旧密码",
          prop: "oldPassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: user.value.oldPassword,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => user.value.oldPassword = $event),
            placeholder: "请输入旧密码",
            type: "password",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "新密码",
          prop: "newPassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: user.value.newPassword,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => user.value.newPassword = $event),
            placeholder: "请输入新密码",
            type: "password",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "确认密码",
          prop: "confirmPassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: user.value.confirmPassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => user.value.confirmPassword = $event),
            placeholder: "请确认新密码",
            type: "password",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            onClick: submit
          }, {
            default: _withCtx(() => [_createTextVNode("保存")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]);
    };
  }
};