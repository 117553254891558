import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, reactive, watch } from "vue";
import { ElMessage } from "element-plus";
import { XiaoniuFrontedControllerService } from "../../../generated/services/XiaoniuFrontedControllerService";

// 接收 props

export default {
  __name: 'UserInfo',
  props: {
    user: {
      type: Object
    }
  },
  setup(__props) {
    const props = __props;

    // 定义表单和校验规则
    const form = reactive({
      nickName: "",
      phonenumber: "",
      email: "",
      sex: ""
    });
    const rules = {
      nickName: [{
        required: true,
        message: "用户昵称不能为空",
        trigger: "blur"
      }],
      email: [
      // {required: true, message: "邮箱地址不能为空", trigger: "blur"},
      {
        type: "email",
        message: "请输入正确的邮箱地址",
        trigger: ["blur", "change"]
      }],
      phonenumber: [
      // {required: true, message: "手机号码不能为空", trigger: "blur"},
      {
        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
        message: "请输入正确的手机号码",
        trigger: "blur"
      }]
    };

    // 创建表单引用
    const formRef = ref(null);

    // 监听 user 的变化
    watch(() => props.user, newUser => {
      if (newUser) {
        form.nickName = newUser.nickName;
        form.phonenumber = newUser.phonenumber;
        form.email = newUser.email;
        form.sex = newUser.sex;
      }
    }, {
      immediate: true
    });

    // 提交表单方法
    const submit = () => {
      formRef.value.validate(valid => {
        if (valid) {
          XiaoniuFrontedControllerService.updateUserProfile(form).then(response => {
            ElMessage.success("修改成功");
            // eslint-disable-next-line vue/no-mutating-props
            props.user.phonenumber = form.phonenumber;
            // eslint-disable-next-line vue/no-mutating-props
            props.user.nickName = form.nickName;
            // eslint-disable-next-line vue/no-mutating-props
            props.user.email = form.email;
          });
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: form,
        rules: rules,
        "label-width": "80px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "用户昵称",
          prop: "nickName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.nickName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.nickName = $event),
            maxlength: "30"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "手机号码",
          prop: "phonenumber"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.phonenumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.phonenumber = $event),
            maxlength: "11"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "邮箱",
          prop: "email"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.email = $event),
            maxlength: "50"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "性别"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_group, {
            modelValue: form.sex,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.sex = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio, {
              value: "0"
            }, {
              default: _withCtx(() => [_createTextVNode("男")]),
              _: 1
            }), _createVNode(_component_el_radio, {
              value: "1"
            }, {
              default: _withCtx(() => [_createTextVNode("女")]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            onClick: submit
          }, {
            default: _withCtx(() => [_createTextVNode("保存")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]);
    };
  }
};