import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteStore } from '@/store/routeStore';
export default defineComponent({
  setup() {
    const router = useRouter();
    const routeStore = useRouteStore();
    router.beforeEach((to, from, next) => {
      routeStore.addRoute(to.path);
      next();
    });
    return {};
  }
});