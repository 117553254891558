import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
import { ref, computed, watch } from 'vue';
import { Check, Delete, Edit, Message, Search, Star, Refresh } from '@element-plus/icons-vue';
export default {
  __name: 'RightToolBar',
  props: {
    /* 是否显示检索条件 */
    showSearch: {
      type: Boolean,
      default: true
    },
    /* 显隐列信息 */
    columns: {
      type: Array
    },
    /* 是否显示检索图标 */
    search: {
      type: Boolean,
      default: true
    },
    /* 显隐列类型（transfer穿梭框、checkbox复选框） */
    showColumnsType: {
      type: String,
      default: "checkbox"
    },
    /* 右外边距 */
    gutter: {
      type: Number,
      default: 10
    }
  },
  emits: ['update:showSearch', 'queryTable'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const value = ref([]);
    const title = ref("显示/隐藏");
    const open = ref(false);
    const style = computed(() => {
      const ret = {};
      if (props.gutter) {
        ret.marginRight = `${props.gutter / 2}px`;
      }
      return ret;
    });
    const toggleSearch = () => {
      emit('update:showSearch', !props.showSearch);
    };
    const refresh = () => {
      emit('queryTable');
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      const _component_el_row = _resolveComponent("el-row");
      return _openBlock(), _createElementBlock("div", {
        class: "top-right-btn",
        style: _normalizeStyle(style.value)
      }, [_createVNode(_component_el_row, null, {
        default: _withCtx(() => [__props.search ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 0,
          class: "item",
          effect: "dark",
          content: __props.showSearch.value ? '隐藏搜索' : '显示搜索',
          placement: "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            circle: "",
            icon: _unref(Search),
            onClick: toggleSearch
          }, null, 8, ["icon"])]),
          _: 1
        }, 8, ["content"])) : _createCommentVNode("", true), _createVNode(_component_el_tooltip, {
          class: "item",
          effect: "dark",
          content: "刷新",
          placement: "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            circle: "",
            icon: _unref(Refresh),
            onClick: refresh
          }, null, 8, ["icon"])]),
          _: 1
        })]),
        _: 1
      })], 4);
    };
  }
};