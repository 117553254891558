import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63aff3f0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "player-progress-area"
};
const _hoisted_2 = {
  class: "player-progress-schedule-wrap"
};
const _hoisted_3 = {
  class: "player-progress-schedule"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "player-progress-wrap",
    onMouseenter: _cache[0] || (_cache[0] = (...args) => $options.handleMouseEnter && $options.handleMouseEnter(...args)),
    onMouseleave: _cache[1] || (_cache[1] = (...args) => $options.handleMouseLeave && $options.handleMouseLeave(...args)),
    ref: "progress"
  }, [_createElementVNode("div", {
    class: _normalizeClass(["player-progress", $data.isActive ? 'state-active' : ''])
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "player-progress-schedule-buffer",
    style: _normalizeStyle(`transform: scaleX(${$props.bufferPer});`)
  }, null, 4), _createElementVNode("div", {
    class: "player-progress-schedule-current",
    style: _normalizeStyle(`transform: scaleX(${$props.currentPer});`)
  }, null, 4)])])], 2)], 544)]);
}