import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRouteStore = defineStore('routeStore', () => {
    const visitedRoutes = ref<string[]>([]);

    function addRoute(path: string) {
        if (visitedRoutes.value.includes(path)) {
            //移除之前的路由
            visitedRoutes.value = visitedRoutes.value.filter(item => item !== path);
        }
        //添加新的路由
        visitedRoutes.value.push(path);
    }

    return { visitedRoutes, addRoute };
});
