import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { ElMessage } from 'element-plus';
import PlayerWrap from '@/components/player/PlayerWrapper.vue';
import { handleTime, handleNum, handleDate, linkify } from '@/plugins/utils.js';
import VideoProfile from "@/components/video/VideoProfile";
import myAxios from "@/plugins/myAxios";
import { getToken } from "@/plugins/myToken";
export default {
  name: "VideoDetail",
  components: {
    VideoProfile,
    PlayerWrap
  },
  data() {
    return {
      socket: null,
      playerSize: {
        width: 704,
        height: 442
      },
      video: {
        // "vid": 1,
        // "uid": 1,
        // "title": "cs",
        // "type": 1,
        // "auth": 0,
        // "duration": 71.4,
        // "mcId": "anime",
        // "scId": "finish",
        // "tags": "经典\r\n",
        // "descr": "124",
        // "coverUrl": "https://xiaoniu-test.oss-cn-hangzhou.aliyuncs.com/20240814/img/cover/1723618796290792efbc415b24b5bb0351c82d9caf60f.jpg",
        // "videoUrl": "https://xiaoniu-test.oss-cn-hangzhou.aliyuncs.com/20240814/video/1723618796791e5823810b1c8400c90daeb276d14d7df.mp4",
        // "status": 1,
        // "uploadDate": "2024-08-14 15:07",
        // "deleteDate": null
      },
      // 视频信息
      view: 0,
      // 播放数
      danmu: 0,
      // 弹幕数
      good: 0,
      // 点赞数
      coin: 0,
      // 投币数
      collect: 0,
      // 收藏数
      share: 0,
      // 分享数
      comment: 0,
      // 评论数
      population: 0,
      // 当前观看人数
      user: {
        uid: 0
      },
      // 投稿用户信息
      category: {},
      // 视频分区信息
      tags: [],
      // 投稿标签
      showAllDesc: true,
      // 是否展开简介
      descTooLong: false,
      // 简介太长需要展开
      jumpTimePoint: -1,
      // 双击弹幕跳转的时间点
      autonext: false,
      // 是否自动连播
      recommendVideos: [],
      // 推荐视频
      vids: [],
      // 存放本视频和已推荐的视频id
      isGifShow: false,
      gifDisplay: false,
      collectVisible: false,
      // 收藏框的显隐
      collectedFids: new Set(),
      // 收藏了该视频的收藏夹ID集合
      isMounted: false,
      loveLoading: false,
      // 点赞防抖
      videoList: [],
      currentPlayingId: null // 当前播放的视频ID
    };
  },
  methods: {
    ////// 请求 //////
    //获取视频选集
    async getVideoList() {
      const res = await myAxios('/baseinfo/fronted/video/listWithVideo', {
        params: {
          type: this.video.type
        }
      });
      this.videoList = res.data.data;
    },
    // 获取视频详细信息
    async getVideoDetail() {
      const res = await myAxios('/baseinfo/fronted/videoDetail', {
        params: {
          id: this.$route.params.id
        }
      });
      if (res.data.code === 404) {
        this.$router.push("/notfound");
        return false;
      }
      if (res.data.data) {
        // console.log("视频详情: ", res.data.data);
        this.video = res.data.data;
        this.view = res.data.data.play;
        this.danmu = res.data.data.danmu;
      }
      // this.isDescTooLong();
      return true;
    },
    // 获取弹幕列表
    async getDanmuList() {
      const res = await myAxios(`/baseinfo/fronted/danmu-list/${this.$route.params.id}`);
      if (res.data.data == null || res.data.data.length === 0) {
        this.$store.commit("updateDanmuList", []);
      } else if (res.data.data.length > 0) {
        this.$store.commit("updateDanmuList", res.data.data);
      }
    },
    // 初始化实时弹幕的websocket
    async initWebsocket() {
      const wsBaseUrl = process.env.VUE_APP_WS_DANMU_URL;
      const socketUrl = `${wsBaseUrl}/ws/danmu/${this.$route.params.id}`;
      if (this.socket != null) {
        await this.socket.close();
        this.socket = null;
      }
      this.socket = new WebSocket(socketUrl);
      // 开启监听
      this.socket.addEventListener('close', this.handleWsClose);
      this.socket.addEventListener('message', this.handleWsMessage);
      this.socket.addEventListener('error', this.handleWsError);
    },
    async closeWebSocket() {
      if (this.socket != null) {
        await this.socket.close();
        this.socket = null;
      }
    },
    ////// 事件 //////
    // 处理播放时长
    handleDuration(time) {
      return handleTime(time);
    },
    // 处理大于一万的数字
    handleNum(number) {
      return handleNum(number);
    },
    // 处理投稿时间
    handleDate(date) {
      return handleDate(date);
    },
    // 处理超链接文本
    handleLinkify(text) {
      return linkify(text);
    },
    // 判断简介长度是否过长需要收起
    isDescTooLong() {
      this.$nextTick(() => {
        const desc = document.querySelector('.basic-desc-info');
        if (desc.clientHeight > 84) {
          this.descTooLong = true;
          this.showAllDesc = false;
        }
      });
    },
    // 打开新标签页
    openNewPage(route) {
      window.open(this.$router.resolve(route).href, '_blank');
    },
    // 处理窗口滚动触发的事件
    handleScroll() {
      const windowHeight = window.innerHeight;
      const leftPart = document.querySelector('.left-container');
      const rightPart = document.querySelector('.right-container-inner');
      if (leftPart.clientHeight <= windowHeight - 64) {
        leftPart.style.top = '64px';
      } else {
        leftPart.style.top = `-${leftPart.clientHeight - windowHeight}px`;
      }
      if (rightPart.clientHeight <= windowHeight - 64) {
        rightPart.style.top = '64px';
      } else {
        rightPart.style.top = `-${rightPart.clientHeight - windowHeight}px`;
      }
    },
    // 窗口大小变动时更新相关宽高
    updatePlayerSize(size) {
      this.playerSize.width = size.width;
      this.playerSize.height = size.height;
    },
    // 处理websocket事件
    handleWsClose() {
      // console.log("弹幕websocket信道关闭,请刷新页面重试");
      setTimeout(() => {
        if (!this.socket) {
          this.initWebsocket(); // 如果两秒后还未重连就手动重连
        }
      }, 2000);
    },
    handleWsMessage(e) {
      if (e.data === '登录已过期') {
        ElMessage.error(e.data);
      } else if (e.data.startsWith("当前观看人数")) {
        let numberPart = e.data.substring(6).trim();
        this.population = parseInt(numberPart, 10);
        // console.log("当前观看人数: ", this.population);
      } else {
        let dm = JSON.parse(e.data);
        // console.log("ws message: ", dm);
        this.$store.state.danmuList.push(dm);
        // console.log("vuex中的弹幕列表: ", this.$store.state.danmuList);
      }
    },
    handleWsError(e) {
      console.log("弹幕websocket信道报错: ", e);
    },
    // 发送弹幕
    sendDanmu(dm) {
      if (localStorage.getItem('userInfo') == null) {
        ElMessage.error("您尚未登录~");
        return;
      }
      const dmJson = JSON.stringify({
        token: getToken(),
        data: dm
      });
      this.socket.send(dmJson);
    },
    // 切换视频
    async changeVideo(vid) {
      await this.$router.push(`/video/detail/${vid}`);
      await this.initWebsocket();
      if (await this.getVideoDetail()) {
        await this.getDanmuList();
        await this.getVideoList(); //获取与这个视频分类相关的视频选集
        this.currentPlayingId = this.$route.params.id;
      }
    },
    // 视频播放结束自动连播
    next() {
      //获取当前视频的下一个视频
      const index = this.videoList.findIndex(item => item.id == this.currentPlayingId);
      let nextVideo = this.videoList[index + 1];
      if (nextVideo) {
        this.changeVideo(nextVideo.id);
      }
    },
    noPage() {
      ElMessage.warning("该功能暂未开放");
    }
  },
  async created() {
    // 同步自动连播
    if (localStorage.getItem("playerSetting")) {
      let setting = JSON.parse(localStorage.getItem("playerSetting"));
      this.autonext = setting.autonext;
    }
    await this.initWebsocket();
    if (await this.getVideoDetail()) {
      await this.getDanmuList();
      await this.getVideoList(); //获取与这个视频分类相关的视频选集
      this.currentPlayingId = this.$route.params.id;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
    window.addEventListener('beforeunload', this.closeWebSocket); // beforeunload 事件监听标签页关闭
    setTimeout(() => {
      this.isMounted = true;
    }, 3000);
  },
  async beforeUnmount() {
    await this.closeWebSocket();
    window.removeEventListener('beforeunload', this.closeWebSocket);
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {}
};