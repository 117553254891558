/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SysDictData } from '../models/SysDictData';
import type { TableDataInfo } from '../models/TableDataInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SysDictDataControllerService {

    /**
     * edit
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static editUsingPut2(
        requestBody?: SysDictData,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/system/dict/data',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * add
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static addUsingPost2(
        requestBody?: SysDictData,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/system/dict/data',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * export
     * @param dictLabel
     * @param dictType
     * @param dictValue
     * @param createBy
     * @param createTime
     * @param cssClass
     * @param _default
     * @param dictCode
     * @param dictSort
     * @param isDefault
     * @param listClass
     * @param params
     * @param remark
     * @param searchValue
     * @param status
     * @param updateBy
     * @param updateTime
     * @returns any OK
     * @throws ApiError
     */
    public static exportUsingPost1(
        dictLabel: string,
        dictType: string,
        dictValue: string,
        createBy?: string,
        createTime?: string,
        cssClass?: string,
        _default?: boolean,
        dictCode?: number,
        dictSort?: number,
        isDefault?: string,
        listClass?: string,
        params?: Record<string, any>,
        remark?: string,
        searchValue?: string,
        status?: string,
        updateBy?: string,
        updateTime?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/system/dict/data/export',
            query: {
                'createBy': createBy,
                'createTime': createTime,
                'cssClass': cssClass,
                'default': _default,
                'dictCode': dictCode,
                'dictLabel': dictLabel,
                'dictSort': dictSort,
                'dictType': dictType,
                'dictValue': dictValue,
                'isDefault': isDefault,
                'listClass': listClass,
                'params': params,
                'remark': remark,
                'searchValue': searchValue,
                'status': status,
                'updateBy': updateBy,
                'updateTime': updateTime,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * list
     * @param dictLabel
     * @param dictType
     * @param dictValue
     * @param createBy
     * @param createTime
     * @param cssClass
     * @param _default
     * @param dictCode
     * @param dictSort
     * @param isDefault
     * @param listClass
     * @param params
     * @param remark
     * @param searchValue
     * @param status
     * @param updateBy
     * @param updateTime
     * @returns TableDataInfo OK
     * @throws ApiError
     */
    public static listUsingGet2(
        dictLabel: string,
        dictType: string,
        dictValue: string,
        createBy?: string,
        createTime?: string,
        cssClass?: string,
        _default?: boolean,
        dictCode?: number,
        dictSort?: number,
        isDefault?: string,
        listClass?: string,
        params?: Record<string, any>,
        remark?: string,
        searchValue?: string,
        status?: string,
        updateBy?: string,
        updateTime?: string,
    ): CancelablePromise<TableDataInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/system/dict/data/list',
            query: {
                'createBy': createBy,
                'createTime': createTime,
                'cssClass': cssClass,
                'default': _default,
                'dictCode': dictCode,
                'dictLabel': dictLabel,
                'dictSort': dictSort,
                'dictType': dictType,
                'dictValue': dictValue,
                'isDefault': isDefault,
                'listClass': listClass,
                'params': params,
                'remark': remark,
                'searchValue': searchValue,
                'status': status,
                'updateBy': updateBy,
                'updateTime': updateTime,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * dictType
     * @param dictType dictType
     * @returns any OK
     * @throws ApiError
     */
    public static dictTypeUsingGet(
        dictType: string,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/system/dict/data/type/{dictType}',
            path: {
                'dictType': dictType,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * remove
     * @param dictCodes dictCodes
     * @returns any OK
     * @throws ApiError
     */
    public static removeUsingDelete2(
        dictCodes: string,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/system/dict/data/{dictCodes}',
            path: {
                'dictCodes': dictCodes,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * getInfo
     * @param dictCode dictCode
     * @returns any OK
     * @throws ApiError
     */
    public static getInfoUsingGet4(
        dictCode: number,
    ): CancelablePromise<Record<string, Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/system/dict/data/{dictCode}',
            path: {
                'dictCode': dictCode,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
