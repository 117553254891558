// store.ts
import { createStore, ActionTree, MutationTree } from 'vuex';
import {SysDept, SysRole} from "../../generated";

interface User{
    avatar?: string;
    createBy?: string;
    createTime?: string;
    delFlag?: string;
    dept?: SysDept;
    deptId?: number;
    email?: string;
    loginDate?: string;
    loginIp?: string;
    nickName?: string;
    params?: Record<string, any>;
    password?: string;
    phonenumber?: string;
    postIds?: Array<number>;
    remark?: string;
    roleId?: number;
    roleIds?: Array<number>;
    roles?: Array<SysRole>;
    sex?: string;
    source?: string;
    status?: string;
    updateBy?: string;
    updateTime?: string;
    userId?: number;
    userName: string;
}

// Define State interface
interface State {
    isLoading: boolean;
    danmuList: any[]; // Replace `any` with a more specific type if available
    ws: WebSocket | null;
    user: User,
}

// Create the Vuex store
export default createStore<State>({
    state: {
        isLoading: false,
        danmuList: [],
        ws: null,
        user: {
            userName: '', // Provide a default value for required properties
        },
    },
    mutations: {
        updateDanmuList(state, danmuList: any[]) {
            state.danmuList = danmuList;
            // console.log("vuex中的弹幕列表: ", state.danmuList);
        },
        setWebSocket(state, ws: WebSocket | null) {
            state.ws = ws;
        },
        handleWsOpen() {
            console.log("实时通信websocket已建立");
        },
        handleWsClose() {
            console.log("实时通信websocket关闭,请登录并刷新页面重试");
        },
        handleWsMessage(state, e: MessageEvent) {
            const data = JSON.parse(e.data);
            // Process the message data
        },
        handleWsError(_, e: Event) {
            console.log("实时通信websocket报错: ", e);
        },
        // 更新当前用户
        updateUser(state, user) {
            state.user = user;
        },
        // 退出登录或登录过期时初始化个别数据
        initData(state) {
            state.user = {
                userName: '', // Provide a default value for required properties
            }
        },
    } as MutationTree<State>, // Cast mutations to MutationTree<State>

    actions: {
        async connectWebSocket({ commit, state }) {
            return new Promise<void>((resolve) => {
                if (state.ws) {
                    state.ws.close();
                    commit('setWebSocket', null); // Close and clear the WebSocket instance
                }
                const wsBaseUrl = process.env.VUE_APP_WS_IM_URL as string;
                const ws = new WebSocket(`${wsBaseUrl}/im`);
                commit('setWebSocket', ws);

                ws.addEventListener('open', () => {
                    commit('handleWsOpen');
                    resolve(); // Resolve the Promise
                });

                ws.addEventListener('close', () => commit('handleWsClose'));
                ws.addEventListener('message', (e) => commit('handleWsMessage', e));
                ws.addEventListener('error', (e) => commit('handleWsError', e));
            });
        },
        async closeWebSocket({ commit, state }) {
            if (state.ws) {
                await state.ws.close();
                commit('setWebSocket', null);
            }
        },
    } as ActionTree<State, State>, // Cast actions to ActionTree<State, State>

});
