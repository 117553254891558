type DebouncedFunction<T extends (...args: any[]) => any> = (
    this: any,
    ...args: Parameters<T>
) => void;

export function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate?: boolean
): DebouncedFunction<T> {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    let args: Parameters<T> | undefined;
    let context: any;
    let timestamp: number;
    let result: ReturnType<T> | undefined;

    const later = function() {
        const last = +new Date() - timestamp;

        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args as Parameters<T>);
                if (!timeout) {
                    context = args = undefined;
                }
            }
        }
    };

    return function(this: any, ...args_: Parameters<T>) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        context= this;
        timestamp = +new Date();
        const callNow = immediate && !timeout;

        if (!timeout) {
            timeout = setTimeout(later, wait);
        }

        if (callNow) {
            result = func.apply(context, args_);
            context = args = undefined;
        }

        return result;
    } as DebouncedFunction<T>;
}
