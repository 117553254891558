import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
import {routes} from "@/router/routes";
import {ref} from "vue";


const router = createRouter({
    // history: createWebHashHistory(process.env.BASE_URL),
    history: createWebHistory(process.env.BASE_URL),
    routes,
    //路由行为配置 回到顶部
    scrollBehavior(to, from, savedPosition) {
        return {top: 0};
    },
})



export default router
