import PlayerLoading from './PlayerLoading.vue';
import LoadingBuff from './LoadingBuff.vue';
import PlayerProgress from './PlayerProgress.vue';
import SliderColumn from '@/components/slider/SliderColumn.vue';
import SliderRow from '@/components/slider/SliderRow.vue';
import ColorPicker from '@/components/color/ColorPicker.vue';
import { handleTime } from '@/plugins/utils.js';
import { ElMessage } from 'element-plus';
import myAxios from "@/plugins/myAxios";
let hideCtrlTimer; // 隐藏控制器的计时器
let clickTimer; // 单击播放/暂停事件的防抖计时器
let menuShowTimer = [null, null, null, null, null, null, null]; // 每个按钮的提示、菜单等显示延时计时器
let menuHideTimer = [null, null, null, null, null, null, null]; // 每个按钮的提示、菜单等隐藏延时计时器
let speedInterval; // 更新缓冲网速的定时任务

export default {
  name: "PlayerWrapper",
  components: {
    PlayerLoading,
    LoadingBuff,
    SliderColumn,
    SliderRow,
    PlayerProgress,
    ColorPicker
  },
  data() {
    return {
      isMounted: false,
      // 做个三秒挂载延迟，防止increasePlay函数重复触发
      wrapSize: {
        width: 704,
        height: 442
      },
      isMobile: false,
      videoWidth: 704,
      videoHeight: 396,
      danmuOpen: true,
      // 是否开启弹幕
      dmOpacityOrder: false,
      // 更新弹幕不透明度slider的命令
      dmList: [],
      // 弹幕列表
      lastTimePoint: 0,
      // 上一个播放时间点，用于筛选将展示的弹幕
      dmIndex: -1,
      // 当前时间点之前的最后一个弹幕的指针，播放更新的时候这个弹幕及之前的都不会展示（使用索引可以提升筛选效率，前提是根据时间点排序的弹幕列表）
      dmSetting: {
        opacity: 100,
        // 弹幕透明度
        dmSpeed: 2 // 弹幕滚动速度，1慢速75px/s 2正常150px/s 4快速300px/s
      },
      rollRow: new Array(12).fill(-1),
      // 滚动模式下用于记录每一行上一个弹幕的全呈现时间
      topRow: new Array(12).fill(-1),
      // 顶部模式下用于记录每一行上一个弹幕的结束时间
      bottomRow: new Array(12).fill(-1),
      // 底部模式下用于记录每一行上一个弹幕的结束时间
      input: '',
      // 要发送的弹幕内容
      canPlay: false,
      // 视频可以播放了
      screenType: 'normal',
      // 屏幕类型，normal 正常模式 web 网页全屏 full 屏幕全屏
      ctrlHidden: true,
      // 控制器是否隐藏
      pause: false,
      // 视频是否暂停中
      buff: false,
      // 视频是否正在缓冲
      firstChangeScreen: true,
      currentPer: 0,
      // 进度条百分比 0~1
      currentTime: 0,
      // 当前播放时间
      bufferPer: 0,
      // 缓冲百分比 0~1
      bufferSpeed: 0.00,
      // 缓冲网速 KB/s
      videoSize: 0,
      // 视频字节大小
      lastByte: 0,
      // 上次缓冲的字节
      isMuted: false,
      // 是否静音
      volumeOrder: false,
      // 更新音量slider的命令
      volume: 35,
      // 音量
      showDmInput: false,
      // 是否显示全屏状态下的弹幕输入框
      playbackrate: '倍速',
      // 倍速文本
      dmTips: false,
      // 弹幕按钮快捷提示是否显示
      fullTips: false,
      // 全屏按钮快捷提示是否显示
      setting: {
        loop: false,
        // 是否开启洗脑循环
        autoplay: true,
        // 是否自动播放
        autonext: false // 是否自动连播
      },
      dmStyle: {
        fontsize: 25,
        // 18 小号 25 标准
        mode: 1,
        // 1 滚动 2 顶部 3底部
        color: '#FFFFFF' // 字体颜色，6位16进制标准格式
      }
    };
  },
  props: {
    // 视频的播放地址
    videoUrl: {
      type: String,
      default() {
        return '';
      }
    },
    // 视频标题
    title: {
      type: String,
      default() {
        return '';
      }
    },
    // 视频总时长
    duration: {
      type: Number,
      default() {
        return 0;
      }
    },
    // UP主资料
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    // 当前观看人数
    population: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 外部时间点跳转并播放指令，大于等于0即触发
    jumpTimePoint: {
      type: Number,
      default() {
        return -1;
      }
    },
    // 外部控制的自动连播
    autonext: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    //////// 请求 /////////
    // 增加一个播放量
    async increasePlay() {
      await myAxios({
        url: "/baseinfo/fronted/video/play/visitor/" + this.$route.params.id,
        method: "get"
      });
    },
    //////// 事件 /////////
    // 根据窗口大小改变播放器的宽高
    changeWindowSize() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      let rightWidth, bottomHeight; // 整体布局的right-container宽度和底部的发送弹幕栏的高度
      if (windowWidth < 1681) {
        rightWidth = 380;
        bottomHeight = 46;
      } else {
        rightWidth = 441;
        bottomHeight = 56;
      }
      let heigth = (windowHeight - 64) * 0.7; // 初始使高为屏高的70%
      let width = (heigth - bottomHeight) * (16 / 9); // 初始根据比例计算宽

      // 限制宽的范围
      if (windowWidth - width - rightWidth < 112) {
        // 表示宽度溢出了，重新计算宽并用其计算高
        width = windowWidth - rightWidth - 112;
        heigth = width * (9 / 16) + bottomHeight;
      }

      // 最后限制高的范围，并以最后高为准计算最后宽
      if (windowWidth < 1681) {
        heigth = Math.max(424, heigth);
      } else {
        heigth = Math.max(434, heigth);
      }
      heigth = Math.min(1010, heigth);
      width = (heigth - bottomHeight) * (16 / 9);

      // 更新宽高
      this.wrapSize.width = width;
      this.wrapSize.height = heigth;
      this.videoWidth = width;
      this.videoHeight = heigth - bottomHeight;
      this.$emit('resize', this.wrapSize);
    },
    // 监听键盘按键触发对应事件
    handleKeyboard(event) {
      // console.log(event);
      // 检查当前焦点元素
      var activeElement = document.activeElement;
      var isInputField = activeElement.tagName == 'INPUT' || activeElement.tagName == 'TEXTAREA';
      //  空格键 播放
      if (event.keyCode === 32 && !isInputField) {
        // 阻止默认的空格键行为（滚动页面）
        event.preventDefault();
        this.changePlayState();
      }
      // F键 全屏
      else if (event.keyCode === 70 && !isInputField) {
        this.changeFullScreen();
      }
      // D键 弹幕
      else if (event.keyCode === 68 && !isInputField) {
        this.changeDanmu();
      }
      // M键 静音
      else if (event.keyCode === 77 && !isInputField) {
        this.changeMutedState();
      }
      // →键 快进5秒
      else if (event.keyCode === 39 && !isInputField) {
        // 阻止默认的→键行为（水平滚动页面）
        event.preventDefault();
        this.$refs.videoPlayer.currentTime += 5;
        // 初始化弹幕索引
        this.removeAllDanmu();
        this.lastTimePoint = this.$refs.videoPlayer.currentTime;
        this.dmIndex = -1;
        this.rollRow = new Array(12).fill(-1);
        this.topRow = new Array(12).fill(-1);
        this.bottomRow = new Array(12).fill(-1);
      }
      // ←键 回退5秒
      else if (event.keyCode === 37 && !isInputField) {
        // 阻止默认的←键行为（水平滚动页面）
        event.preventDefault();
        this.$refs.videoPlayer.currentTime -= 5;
        // 初始化弹幕索引
        this.removeAllDanmu();
        this.lastTimePoint = this.$refs.videoPlayer.currentTime;
        this.dmIndex = -1;
        this.rollRow = new Array(12).fill(-1);
        this.topRow = new Array(12).fill(-1);
        this.bottomRow = new Array(12).fill(-1);
      }
      // ↑键 音量+10
      else if (event.keyCode === 38 && !isInputField) {
        // 阻止默认的↑键行为（滚动页面）
        event.preventDefault();
        // console.log("this.volume的值: ", this.volume);
        let volume = (this.volume + 10) / 100;
        // console.log("volume的值: ", volume);
        volume = Math.min(1, volume);
        volume = Math.max(0, volume);
        this.changeVolume(volume);
        this.volumeOrder = true;
      }
      // ↓键 音量-10
      else if (event.keyCode === 40 && !isInputField) {
        // 阻止默认的↓键行为（滚动页面）
        event.preventDefault();
        let volume = (this.volume - 10) / 100;
        volume = Math.min(1, volume);
        volume = Math.max(0, volume);
        this.changeVolume(volume);
        this.volumeOrder = true;
      }
    },
    // 视频已经加载好了可以播放
    async videoCanPlay() {
      this.canPlay = true;
      const durationInSeconds = this.$refs.videoPlayer.duration;
      // 使用720p视频的估计平均比特率（假设 1Mbps）
      const averageBitrate720p = 1000000;
      // 计算估计的视频大小（单位：字节）
      this.videoSize = durationInSeconds * averageBitrate720p / 8;
      if (!this.pause) {
        try {
          await this.$refs.videoPlayer.play();
          this.pause = false;
        } catch (err) {
          this.pause = true;
        }
      }
    },
    // 显示控制器
    showCtrl() {
      clearTimeout(hideCtrlTimer);
      this.ctrlHidden = false;
      hideCtrlTimer = setTimeout(() => {
        this.ctrlHidden = true;
      }, 1000);
    },
    // 一直显示控制器
    showCtrlAlweys() {
      clearTimeout(hideCtrlTimer);
      this.ctrlHidden = false;
    },
    // 处理单击防抖
    handleClick() {
      clearTimeout(clickTimer);
      clickTimer = setTimeout(() => {
        this.changePlayState();
      }, 300);
    },
    // 双击清除防抖计时器并全屏
    handleDblClick() {
      clearTimeout(clickTimer);
      this.changeFullScreen();
    },
    // 改变视频播放状态
    changePlayState() {
      if (this.pause) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    },
    // 播放
    playVideo() {
      this.pause = false;
      this.$refs.videoPlayer.play();
      speedInterval = setInterval(this.updateBufferSpeed, 1000); // 启动定时更新缓冲网速任务
    },
    // 暂停
    pauseVideo() {
      this.pause = true;
      this.$refs.videoPlayer.pause();
      clearInterval(speedInterval); // 清除定时任务
    },
    // 改变播放设置
    changeSetting(i) {
      if (i === 1) {
        if (this.setting.loop) {
          this.setting.loop = false;
        } else {
          this.setting.loop = true;
        }
      } else if (i === 2) {
        if (this.setting.autoplay) {
          this.setting.autoplay = false;
        } else {
          this.setting.autoplay = true;
        }
      } else if (i === 3) {
        if (this.setting.autonext) {
          this.setting.autonext = false;
        } else {
          this.setting.autonext = true;
        }
        this.$emit("update:autonext", this.setting.autonext);
      }
      const setting = JSON.stringify(this.setting);
      localStorage.setItem('playerSetting', setting);
    },
    // 点击小喇叭切换静音状态
    changeMutedState() {
      if (this.isMuted) {
        this.volume = localStorage.getItem('volume');
        this.isMuted = false;
      } else {
        this.volume = 0;
        this.isMuted = true;
      }
      this.$refs.videoPlayer.volume = this.volume / 100;
      this.volumeOrder = true;
    },
    // 改变音量
    changeVolume(per) {
      let volume = Math.floor(per * 100);
      this.volume = volume;
      this.$refs.videoPlayer.volume = volume / 100;
      if (this.isMuted && volume > 0) {
        this.isMuted = false;
      } else if (volume == 0 && !this.isMuted) {
        this.isMuted = true;
      }
      localStorage.setItem("volume", JSON.stringify(volume));
    },
    // 全屏/退出全屏
    changeFullScreen() {
      if (this.screenType !== 'full') {
        this.fullSrceen();
      } else {
        this.exitFullscreen();
      }
    },
    // 全屏
    fullSrceen() {
      this.screenType = 'full';
      const videoArea = document.querySelector('.player-video-area');
      if (videoArea.requestFullscreen) {
        videoArea.requestFullscreen();
      } else if (videoArea.mozRequestFullScreen) {
        videoArea.mozRequestFullScreen();
      } else if (videoArea.webkitRequestFullscreen) {
        videoArea.webkitRequestFullscreen();
      } else if (videoArea.msRequestFullscreen) {
        videoArea.msRequestFullscreen();
      }
      // 尝试强制横屏
      // if (screen.orientation) {
      //     screen.orientation.lock("landscape");
      // }
    },
    // 退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.screenType = 'normal';
    },
    // 处理全屏模式下按esc键触发的退出全屏事件
    handleVideoResize() {
      const videoArea = document.querySelector('.player-video-area');
      if (videoArea.clientWidth < window.innerWidth - 1 && videoArea.clientHeight < window.innerHeight - 1 && this.screenType !== 'normal') {
        this.screenType = 'normal';
      }
      if (videoArea.clientWidth > 980) {
        this.showDmInput = true;
      } else {
        this.showDmInput = false;
      }
    },
    // 打开关闭弹幕
    changeDanmu() {
      if (this.danmuOpen) {
        this.danmuOpen = false;
        localStorage.setItem("danmuOpen", 'off');
      } else {
        this.danmuOpen = true;
        localStorage.setItem("danmuOpen", 'on');
      }
    },
    // 更新弹幕透明度
    changeDmOpacity(per) {
      let opacity = Math.floor(per * 100);
      this.dmSetting.opacity = opacity;
    },
    // 更新弹幕列表（按时间点排序）
    updateDanmuList(dms) {
      this.dmList = dms;
      this.dmList.sort((a, b) => a.timePoint - b.timePoint);
    },
    // 加载展示弹幕
    displayDanmus(currTimePoint) {
      // console.log(currTimePoint, this.lastTimePoint);
      const dmWrap = document.querySelector('.player-row-dm-wrap');
      const screen = document.querySelector('.player-video-area');
      // 选出当前时间需要展示的弹幕
      // 一定要 lastTimePoint < timePoint <= currTimePoint
      // 发送弹幕时一定要是 this.currentTime 不能是this.$refs.videoPlayer.currentTime
      // 并且需要手动挂载新弹幕，否则会有概率出现弹幕不显示情况
      while (this.dmIndex + 1 < this.dmList.length && this.dmList[this.dmIndex + 1].timePoint <= currTimePoint) {
        const dm = this.dmList[this.dmIndex + 1];
        if (dm.timePoint > this.lastTimePoint) {
          // 如果该弹幕处在当前时间区间，就挂载到document
          const danmuElement = document.createElement('div');
          danmuElement.classList.add('dm');
          danmuElement.innerText = dm.content;
          danmuElement.style.setProperty('--opacity', `${this.dmSetting.opacity / 100}`);
          danmuElement.style.setProperty('--fontSize', `${dm.fontsize}px`);
          danmuElement.style.setProperty('--color', `${dm.color}`);
          // 标识自己发的弹幕
          console.log(this.$store.state.user);
          if (this.$store.state.user.userId && dm.uid === this.$store.state.user.userId) {
            danmuElement.style.setProperty('border', '2px solid #FFFFFF');
          }
          dmWrap.appendChild(danmuElement);
          if (dm.mode === 1) {
            // 滚动模式
            danmuElement.classList.add('roll');
            const contentWidth = danmuElement.offsetWidth; // 内容长度
            const distance = screen.offsetWidth + contentWidth; // 总位移距离
            danmuElement.style.setProperty('--offset', `${screen.offsetWidth}px`);
            danmuElement.style.setProperty('--translateX', `-${distance}px`);
            danmuElement.style.setProperty('--duration', `${distance / (this.dmSetting.dmSpeed * 75)}s`);
            // 计算弹幕位置
            const rowOutTime = (contentWidth + 10) / (this.dmSetting.dmSpeed * 75) + currTimePoint; // 当前弹幕全部呈现时的时间点
            let i = 0,
              full = false,
              min = -1,
              minIndex = 0;
            while (i < 12) {
              if (this.rollRow[i] <= currTimePoint) {
                danmuElement.style.setProperty('--top', `${i * 30 + 4}px`);
                this.rollRow[i] = rowOutTime;
                break;
              }
              // 记录最早全呈现的一行的时间点，以便全部行都占有弹幕时选出一行放新弹幕
              if (min === -1 || this.rollRow[i] < min) {
                min = this.rollRow[i];
                minIndex = i;
              }
              i++;
              if (i === 12) {
                full = true;
              }
            }
            if (full) {
              danmuElement.style.setProperty('--top', `${minIndex * 30 + 4}px`);
              this.rollRow[minIndex] = rowOutTime;
            }
          } else if (dm.mode === 2) {
            // 顶部
            danmuElement.classList.add('center');
            const duration = this.dmSetting.dmSpeed === 1 ? 6 : this.dmSetting.dmSpeed === 2 ? 4 : 2;
            danmuElement.style.setProperty('--duration', `${duration}s`);
            // 计算弹幕位置
            const rowOutTime = duration + currTimePoint; // 当前弹幕消失时的时间点
            let i = 0,
              full = false,
              min = -1,
              minIndex = 0;
            while (i < 12) {
              if (this.topRow[i] <= currTimePoint) {
                danmuElement.style.setProperty('--top', `${i * 30 + 4}px`);
                this.topRow[i] = rowOutTime;
                break;
              }
              // 记录最早消失的一行的时间点，以便全部行都占有弹幕时选出一行放新弹幕
              if (min === -1 || this.topRow[i] < min) {
                min = this.topRow[i];
                minIndex = i;
              }
              i++;
              if (i === 12) {
                full = true;
              }
            }
            if (full) {
              danmuElement.style.setProperty('--top', `${minIndex * 30 + 4}px`);
              this.topRow[minIndex] = rowOutTime;
            }
          } else {
            // 底部
            danmuElement.classList.add('center');
            const duration = this.dmSetting.dmSpeed === 1 ? 6 : this.dmSetting.dmSpeed === 2 ? 4 : 2;
            danmuElement.style.setProperty('--duration', `${duration}s`);
            // 计算弹幕位置
            const rowOutTime = duration + currTimePoint; // 当前弹幕消失时的时间点
            let i = 0,
              full = false,
              min = -1,
              minIndex = 0;
            while (i < 12) {
              if (this.bottomRow[i] <= currTimePoint) {
                danmuElement.style.setProperty('bottom', `${i * 30 + 5}px`);
                this.bottomRow[i] = rowOutTime;
                break;
              }
              // 记录最早消失的一行的时间点，以便全部行都占有弹幕时选出一行放新弹幕
              if (min === -1 || this.bottomRow[i] < min) {
                min = this.bottomRow[i];
                minIndex = i;
              }
              i++;
              if (i === 12) {
                full = true;
              }
            }
            if (full) {
              danmuElement.style.setProperty('bottom', `${minIndex * 30 + 5}px`);
              this.bottomRow[minIndex] = rowOutTime;
            }
          }
          // 动画结束就移除弹幕
          danmuElement.addEventListener('animationend', () => {
            danmuElement.remove();
          });
        }
        this.dmIndex++;
      }
    },
    // 挂载刚刚发送的弹幕
    displaySentDanmus() {
      const dmWrap = document.querySelector('.player-row-dm-wrap');
      const screen = document.querySelector('.player-video-area');
      const danmuElement = document.createElement('div');
      danmuElement.classList.add('dm');
      danmuElement.innerText = this.input;
      danmuElement.style.setProperty('--opacity', `${this.dmSetting.opacity / 100}`);
      danmuElement.style.setProperty('--fontSize', `${this.dmStyle.fontsize}px`);
      danmuElement.style.setProperty('--color', `${this.dmStyle.color}`);
      danmuElement.style.setProperty('border', '2px solid #FFFFFF');
      dmWrap.appendChild(danmuElement);
      if (this.dmStyle.mode === 1) {
        // 滚动模式
        danmuElement.classList.add('roll');
        const contentWidth = danmuElement.offsetWidth; // 内容长度
        const distance = screen.offsetWidth + contentWidth; // 总位移距离
        danmuElement.style.setProperty('--offset', `${screen.offsetWidth}px`);
        danmuElement.style.setProperty('--translateX', `-${distance}px`);
        danmuElement.style.setProperty('--duration', `${distance / (this.dmSetting.dmSpeed * 75)}s`);
        // 计算弹幕位置
        const rowOutTime = (contentWidth + 10) / (this.dmSetting.dmSpeed * 75) + this.currentTime; // 当前弹幕全部呈现时的时间点
        let i = 0,
          full = false,
          min = -1,
          minIndex = 0;
        while (i < 12) {
          if (this.rollRow[i] <= this.currentTime) {
            danmuElement.style.setProperty('--top', `${i * 30 + 4}px`);
            this.rollRow[i] = rowOutTime;
            break;
          }
          // 记录最早全呈现的一行的时间点，以便全部行都占有弹幕时选出一行放新弹幕
          if (min === -1 || this.rollRow[i] < min) {
            min = this.rollRow[i];
            minIndex = i;
          }
          i++;
          if (i === 12) {
            full = true;
          }
        }
        if (full) {
          danmuElement.style.setProperty('--top', `${minIndex * 30 + 4}px`);
          this.rollRow[minIndex] = rowOutTime;
        }
      } else if (this.dmStyle.mode === 2) {
        // 顶部
        danmuElement.classList.add('center');
        const duration = this.dmSetting.dmSpeed === 1 ? 6 : this.dmSetting.dmSpeed === 2 ? 4 : 2;
        danmuElement.style.setProperty('--duration', `${duration}s`);
        // 计算弹幕位置
        const rowOutTime = duration + this.currentTime; // 当前弹幕消失时的时间点
        let i = 0,
          full = false,
          min = -1,
          minIndex = 0;
        while (i < 12) {
          if (this.topRow[i] <= this.currentTime) {
            danmuElement.style.setProperty('--top', `${i * 30 + 4}px`);
            this.topRow[i] = rowOutTime;
            break;
          }
          // 记录最早消失的一行的时间点，以便全部行都占有弹幕时选出一行放新弹幕
          if (min === -1 || this.topRow[i] < min) {
            min = this.topRow[i];
            minIndex = i;
          }
          i++;
          if (i === 12) {
            full = true;
          }
        }
        if (full) {
          danmuElement.style.setProperty('--top', `${minIndex * 30 + 4}px`);
          this.topRow[minIndex] = rowOutTime;
        }
      } else {
        // 底部
        danmuElement.classList.add('center');
        const duration = this.dmSetting.dmSpeed === 1 ? 6 : this.dmSetting.dmSpeed === 2 ? 4 : 2;
        danmuElement.style.setProperty('--duration', `${duration}s`);
        // 计算弹幕位置
        const rowOutTime = duration + this.currentTime; // 当前弹幕消失时的时间点
        let i = 0,
          full = false,
          min = -1,
          minIndex = 0;
        while (i < 12) {
          if (this.bottomRow[i] <= this.currentTime) {
            danmuElement.style.setProperty('bottom', `${i * 30 + 5}px`);
            this.bottomRow[i] = rowOutTime;
            break;
          }
          // 记录最早消失的一行的时间点，以便全部行都占有弹幕时选出一行放新弹幕
          if (min === -1 || this.bottomRow[i] < min) {
            min = this.bottomRow[i];
            minIndex = i;
          }
          i++;
          if (i === 12) {
            full = true;
          }
        }
        if (full) {
          danmuElement.style.setProperty('bottom', `${minIndex * 30 + 5}px`);
          this.bottomRow[minIndex] = rowOutTime;
        }
      }
      // 动画结束就移除弹幕
      danmuElement.addEventListener('animationend', () => {
        danmuElement.remove();
      });
    },
    // 移除挂载的全部弹幕
    removeAllDanmu() {
      const dmWrap = document.querySelector('.player-row-dm-wrap');
      const dmElements = dmWrap.querySelectorAll('.dm');
      dmElements.forEach(dmElement => {
        dmElement.remove();
      });
    },
    // 发送弹幕
    sendDm() {
      if (localStorage.getItem('userInfo') == null) {
        ElMessage.error("您尚未登录~");
        return;
      }
      if (this.input.trim().length === 0) {
        ElMessage.error("写点东西吧");
        return;
      }
      const dm = {
        content: this.input,
        fontsize: this.dmStyle.fontsize,
        mode: this.dmStyle.mode,
        color: this.dmStyle.color,
        timePoint: this.currentTime // 不能是this.$refs.videoPlayer.currentTime
      };
      this.$emit("sendDm", dm);
      //挂载到视频页面上
      this.displaySentDanmus();
      this.input = '';
    },
    // 外部控制的时间跳转（双击弹幕）
    jumpTimePointAndPlay(time) {
      // 计算进度条的位置
      this.currentPer = time / this.duration;
      this.currentTime = time;
      this.$refs.videoPlayer.currentTime = time;
      this.initDanmuIndex(time);
      this.playVideo();
    },
    // 拖动进度条的回调
    changeCurrentPer(curr) {
      this.currentPer = curr;
      // 计算当前时间
      let time = curr * this.duration;
      this.currentTime = time;
      this.$refs.videoPlayer.currentTime = time;
      this.initDanmuIndex(time);
    },
    // 初始化弹幕索引
    initDanmuIndex(time) {
      this.removeAllDanmu();
      this.lastTimePoint = time;
      this.dmIndex = -1;
      this.rollRow = new Array(12).fill(-1);
      this.topRow = new Array(12).fill(-1);
      this.bottomRow = new Array(12).fill(-1);
    },
    // 实时更新当前播放的时间
    timeUpdate() {
      if (!this.$refs.videoPlayer) {
        return;
      }
      const time = this.$refs.videoPlayer.currentTime;
      // 如果是0就表示视频被循环播放了，需要初始化弹幕索引以及增加一个播放量
      if (time === 0) {
        this.initDanmuIndex();
        this.increasePlay();
      }
      this.currentTime = time;
      // 计算进度条的位置
      this.currentPer = time / this.duration;
      // 展示该时间段的弹幕
      if (this.danmuOpen) {
        this.displayDanmus(time);
      }
      this.lastTimePoint = time;
    },
    // 更新缓冲条
    updateBufferingBar() {
      const video = this.$refs.videoPlayer;
      const buffered = video.buffered;
      if (buffered.length > 0) {
        // 计算已缓冲的百分比并更新缓冲进度条
        this.bufferPer = buffered.end(buffered.length - 1) / this.duration;
      }
    },
    // 计算缓冲网速
    updateBufferSpeed() {
      const buffered = this.$refs.videoPlayer.buffered;
      if (buffered && buffered.length > 0) {
        let currByte = buffered.end(buffered.length - 1) / this.duration * this.videoSize;
        let differ = currByte - this.lastByte; // 在1s内加载了这么多字节
        this.bufferSpeed = (differ / 1000).toFixed(2);
        this.lastByte = currByte;
      }
    },
    // 调整倍速
    changePlaybackRate(num) {
      if (num === 1.0) {
        this.playbackrate = '倍速';
      } else if (num === 2.0) {
        this.playbackrate = '2.0x';
      } else {
        this.playbackrate = `${num}x`;
      }
      this.$refs.videoPlayer.playbackRate = num;
    },
    // 鼠标悬停按钮时打开菜单
    enterBtn(name, index) {
      clearTimeout(menuHideTimer[index]);
      menuShowTimer[index] = setTimeout(() => {
        const obj = document.querySelector(`.${name}`);
        obj.classList.add('state-show');
      }, 200);
    },
    // 鼠标移出按钮时关闭菜单
    leaveBtn(name, index) {
      clearTimeout(menuShowTimer[index]);
      menuHideTimer[index] = setTimeout(() => {
        const obj = document.querySelector(`.${name}`);
        obj.classList.remove('state-show');
      }, 200);
    },
    // 格式化时间
    handleTime(time) {
      return handleTime(time);
    },
    // 视频播放结束
    ended() {
      if (this.setting.autonext) {
        this.$emit("next");
      }
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // 根据屏幕宽度设置 isMobile
    }
  },
  created() {
    // 同步音量
    if (localStorage.getItem("volume")) {
      this.volume = JSON.parse(localStorage.getItem("volume"));
    } else {
      localStorage.setItem("volume", JSON.stringify(this.volume));
    }
    // 同步用户的弹幕开关设置
    if (localStorage.getItem("danmuOpen") === 'off') {
      this.danmuOpen = false;
    }
    // 同步弹幕设置
    if (localStorage.getItem("dmSetting")) {
      this.dmSetting = JSON.parse(localStorage.getItem("dmSetting"));
    }
    // 同步播放设置
    if (localStorage.getItem("playerSetting")) {
      this.setting = JSON.parse(localStorage.getItem("playerSetting"));
    }
    // 判断是否允许自动播放
    if (!this.setting.autoplay) {
      this.pause = true;
    }
    // 同步弹幕风格
    if (localStorage.getItem("dmStyle")) {
      this.dmStyle = JSON.parse(localStorage.getItem("dmStyle"));
    }
    // 获取弹幕列表
    // this.updateDanmuList(this.$store.state.danmuList);
  },
  mounted() {
    this.changeWindowSize();
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    window.addEventListener('resize', this.changeWindowSize);
    window.addEventListener('resize', this.handleVideoResize);
    document.addEventListener('keydown', e => this.handleKeyboard(e));
    // 监听 progress 事件，该事件在缓冲状态发生变化时触发
    // this.$refs.videoPlayer.addEventListener('progress', this.updateBufferingBar);
    // 默认播放音量不等于this.volume，所以挂载时更新同步一下音量
    this.$refs.videoPlayer.volume = this.volume / 100;
    setTimeout(() => {
      this.isMounted = true;
    }, 3000);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.changeWindowSize);
    document.removeEventListener('keydown', e => this.handleKeyboard(e));
    window.removeEventListener('resize', this.handleVideoResize);
    this.pauseVideo();
    window.removeEventListener('resize', this.checkScreenSize);
  },
  watch: {
    "dmStyle.fontsize"() {
      localStorage.setItem("dmStyle", JSON.stringify(this.dmStyle));
    },
    "dmStyle.mode"() {
      localStorage.setItem("dmStyle", JSON.stringify(this.dmStyle));
    },
    "dmStyle.color"() {
      localStorage.setItem("dmStyle", JSON.stringify(this.dmStyle));
    },
    "dmSetting.opacity"() {
      localStorage.setItem("dmSetting", JSON.stringify(this.dmSetting));
      this.dmOpacityOrder = true;
    },
    "dmSetting.dmSpeed"() {
      localStorage.setItem("dmSetting", JSON.stringify(this.dmSetting));
    },
    // 深度监听vuex中弹幕列表的变化，因为对于数组的push操作并不是总能监听到
    "$store.state.danmuList": {
      handler(curr) {
        this.updateDanmuList(curr.slice());
      },
      deep: true
    },
    "jumpTimePoint"(curr) {
      if (curr >= 0) {
        this.jumpTimePointAndPlay(curr);
        this.$emit("update:jumpTimePoint", -1);
      }
    },
    "autonext"(curr) {
      this.setting.autonext = curr;
      const setting = JSON.stringify(this.setting);
      localStorage.setItem('playerSetting', setting);
    },
    // 更换视频时初始化
    "videoUrl"() {
      this.canPlay = false;
      this.initDanmuIndex(0);
    },
    // 监听登录状态，如果重新登录就要新增一个播放记录
    "$store.state.isLogin"(curr) {
      if (this.isMounted && curr) {
        this.increasePlay();
      }
    }
  }
};