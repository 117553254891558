import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-light.css' // 代码高亮的样式
export const highLightPlugin = {
    install(app: any) {
        //定义全局指令
        app.directive('highlight', {
            updated(el: any) {
                const blocks = el.querySelectorAll('pre code')
                //设置高亮样式
                blocks.forEach((block: any) => {
                    hljs.highlightElement(block);
                });
            }
        })
    }
}


