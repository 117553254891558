import axios from "axios";
import {getToken} from "@/plugins/myToken";
import { ElMessage } from 'element-plus'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const instance = axios.create({
    baseURL: '/prod-api',
    timeout: 10000,
});


// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (getToken()) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    let { message } = error
    if (message === 'Network Error') {
        message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
        message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
        message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    ElMessage({
        message: message,
        type: 'error',
        duration: 5 * 1000,
        plain: true,
    })
    return Promise.reject(error);
});

export default instance;
