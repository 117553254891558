/**
 * 检查权限（判断当前用户是否具有某个权限）
 * 鉴定权限 当前用户
 * @param loginUser当前用户 needAccess需要的权限
 * @return boolean 有无权限
 */
import ACCESS_ENUM from "@/access/ACCESS_ENUM";

const checkAccess = (loginUser: any, needAccess: string = ACCESS_ENUM.NOT_LOGIN) => {
    //用户默认的权限为未登录
    const loginUserAccess = loginUser?.userRole ?? ACCESS_ENUM.NOT_LOGIN
    if (needAccess === ACCESS_ENUM.NOT_LOGIN) {
        return true;
    }
    if (needAccess === ACCESS_ENUM.USER) {
        if (loginUserAccess === ACCESS_ENUM.NOT_LOGIN) {
            return false;
        }
    }
    if (needAccess === ACCESS_ENUM.VIP) {
        if (loginUserAccess !== ACCESS_ENUM.VIP){
              return false;
        }
    }
    return true;
}
export default checkAccess;
