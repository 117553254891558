import {RouteRecordRaw} from "vue-router";
import UserLayout from "@/components/layout/UserLayout.vue";
import OrderPage from '@/views/OrderView.vue';
import NoOpenView from '@/views/NoOpenView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import PersonPage from '@/views/PersonView.vue';
import MemberView from '@/views/MemberView.vue';
import NoAuthPage from '@/views/NoAuthView.vue';
import VideoView from '@/views/VideoView.vue';
import TeachnologyView from '@/views/TeachnologyView.vue';
import PsView from '@/views/PsView.vue';
import ACCESS_ENUM from "@/access/ACCESS_ENUM";
import UserLoginPage from "@/views/UserLoginView.vue";
import UserRegisterPage from "@/views/UserRegisterView.vue";
import HomePage from "@/views/HomeView.vue";
import ProjectPage from "@/views/ProjectView.vue";
import {
    HomeOutlined, AccountBookOutlined, UserOutlined, AppstoreOutlined, CloudOutlined,
    SmileOutlined,PlaySquareOutlined,BulbOutlined,FolderOutlined,FormOutlined
} from '@ant-design/icons-vue';

import ProjectDetail from "@/components/project/ProjectDetail.vue";
import CourseDetail from "@/components/courseDegin/CourseDetail.vue";
import ArticleDetail from "@/components/article/ArticleDetail.vue";
import VideoDetail from "@/components/video/VideoDetail.vue";
import CourseDesignList from "@/components/courseDegin/CourseDesignList.vue";

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/user',
        name: "用户",
        component: UserLayout,
        meta: {
            visible: true,
        },
        children: [
            {
                path: '/user/login',
                name: "用户登录",
                component: UserLoginPage,
            },
            {
                path: '/user/register',
                name: "用户注册",
                component: UserRegisterPage,
            },
        ]
    },
    {
        path: '/',
        name: "首页",
        component: HomePage,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            icon: HomeOutlined
        }
    },
    // {
    //     path: '/business',
    //     name: "业务服务",
    //     meta: {
    //         access: ACCESS_ENUM.NOT_LOGIN,
    //         icon: CloudOutlined
    //     },
    //     children: [
    //         {
    //             path: '/business/technology',
    //             name: "技术创新",
    //             component: TeachnologyView,
    //         },
    //         {
    //             path: '/business/video',
    //             name: "视频教学",
    //             component: VideoView,
    //         },
    //         {
    //             path: '/business/interview',
    //             name: "面试题解",
    //             component: NoOpenView,
    //         },
    //         {
    //             path: '/business/project',
    //             name: "项目源码",
    //             component: ProjectPage,
    //         },
    //     ]
    // },


    {
        path: '/business/technology',
        name: "AI 对话",
        component: NoOpenView,
            meta: {
                access: ACCESS_ENUM.NOT_LOGIN,
                icon: CloudOutlined
            },
    },
    {
        path: '/business/video',
        name: "视频教学",
        component: VideoView,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            icon: PlaySquareOutlined
        },
    },
    {
        path: '/business/interview',
        name: "面试题解",
        component: NoOpenView,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            icon: BulbOutlined
        },
    },
    {
        path: '/business/project',
        name: "项目源码",
        component: ProjectPage,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            icon: FolderOutlined
        },
    },
    // {
    //     path: '/business/ps',
    //     name: "平面设计",
    //     component: PsView,
    //     meta: {
    //         access: ACCESS_ENUM.NOT_LOGIN,
    //         icon: FormOutlined
    //     },
    // },

    {
        path: '/order',
        name: '我的订单',
        component: OrderPage,
        meta: {
            access: ACCESS_ENUM.USER,
            icon: AccountBookOutlined
        }
    },
    {
        path: '/person',
        name: '个人中心',
        component: PersonPage,
        meta: {
            access: ACCESS_ENUM.USER,
            icon: UserOutlined
        }
    },
    {
        path: '/member',
        name: '会员中心',
        component: MemberView,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            icon: SmileOutlined
        }
    },

    {
        path: '/noAuth',
        name: '没登陆',
        component: NoAuthPage,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            visible: true,
        }
    },


    {
        path: '/notfound',
        name: '404',
        component: NotFoundView,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            visible: true,
        }
    },

    {
        path: '/business/project/detail/:id',
        name: '项目详情',
        component: ProjectDetail,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            visible: true,
        }
    },

    {
        path: '/business/courseDesign/detail/:id',
        name: '课设详情',
        component: CourseDetail,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            visible: true,
        }
    },


    {
        path: '/article/detail/:id',
        name: '文章详情',
        component: ArticleDetail,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            visible: true,
        }
    },


    {
        path: '/video/detail/:id',
        name: '视频详情',
        component: VideoDetail,
        meta: {
            access: ACCESS_ENUM.NOT_LOGIN,
            visible: true,
        }
    },


];
