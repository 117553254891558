export default {
  name: "SliderRow",
  data() {
    return {
      isDragging: false,
      // 是否正在拖动
      barScaleX: 0,
      // 滑动条的X轴缩放百分比 0~1
      thumbOffsetX: 0 // 滑块X轴的向右偏移量 单位px
    };
  },
  props: {
    // 当前进度百分比 小数0~1
    currentPer: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 滑块总宽度
    sliderWidth: {
      type: Number,
      default() {
        return 12;
      }
    },
    // 更新信号
    order: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    // 初始化滑块位置
    initPosition() {
      this.barScaleX = this.currentPer;
      this.thumbOffsetX = (this.sliderWidth - 12) * this.currentPer;
      this.$emit("update:order", false);
    },
    // 初始化拖动
    initDrag() {
      const slider = document.querySelector('.slider-row');
      let offsetX, currPer; // currPer 取值 0~1

      // 鼠标按下事件处理程序
      slider.addEventListener("mousedown", e => {
        this.isDragging = true;
        offsetX = e.clientX - slider.getBoundingClientRect().left;
        currPer = offsetX / slider.getBoundingClientRect().width; // 计算进度比例
        this.barScaleX = currPer;
        this.thumbOffsetX = (slider.getBoundingClientRect().width - 12) * currPer;
        this.$emit("changeCurrent", currPer); // 更新
      });

      // 移动端 触碰事件处理程序
      slider.addEventListener("touchstart", e => {
        this.isDragging = true;
        offsetX = e.touches[0].clientX - slider.getBoundingClientRect().left;
        currPer = offsetX / slider.getBoundingClientRect().width; // 计算进度比例
        this.barScaleX = currPer;
        this.thumbOffsetX = (slider.getBoundingClientRect().width - 12) * currPer;
        this.$emit("changeCurrent", currPer); // 更新
      });

      // 鼠标移动事件处理程序
      document.addEventListener("mousemove", e => {
        if (!this.isDragging) return;
        offsetX = e.clientX - slider.getBoundingClientRect().left;
        currPer = offsetX / slider.getBoundingClientRect().width; // 计算进度比例
        // 边界值判定
        currPer = Math.max(0, currPer);
        currPer = Math.min(1, currPer);
        this.barScaleX = currPer;
        this.thumbOffsetX = (slider.getBoundingClientRect().width - 12) * currPer;
        this.$emit("changeCurrent", currPer); // 更新
      });

      // 移动端 移动事件处理程序
      document.addEventListener("touchmove", e => {
        if (!this.isDragging) return;
        e.preventDefault();
        offsetX = e.touches[0].clientX - slider.getBoundingClientRect().left;
        currPer = offsetX / slider.getBoundingClientRect().width; // 计算进度比例
        // 边界值判定
        currPer = Math.max(0, currPer);
        currPer = Math.min(1, currPer);
        this.barScaleX = currPer;
        this.thumbOffsetX = (slider.getBoundingClientRect().width - 12) * currPer;
        this.$emit("changeCurrent", currPer); // 更新
      }, {
        passive: false
      });

      // 鼠标释放事件处理程序
      document.addEventListener("mouseup", () => {
        this.isDragging = false;
      });

      // 移动端 释放事件处理程序
      document.addEventListener("touchend", () => {
        this.isDragging = false;
      });
    }
  },
  mounted() {
    this.initPosition();
    this.initDrag();
  },
  watch: {
    "order"(curr) {
      if (curr) {
        this.initPosition();
      }
    }
  }
};